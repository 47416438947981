import React, { useMemo } from 'react';
import { Box, Grid, Paper, Tabs, Tab } from '@miyagami-com/lsx-ui-components';
import { useIntl } from 'react-intl';
import { useParams, useHistory, Route, Switch, Redirect } from 'react-router';
import DefaultLayout from '../../Unknown/DefaultLayout';
import ExchangeSettingSportline from '../ExchangeSettingSportline';
import ExchangeSettingsDateRangeTab from '../ExchangeSettingsDateRangeTab';
import messages from './messages';
import useStyles from './useStyles';
import ExchangeSettingsMarketTypes from '../ExchangeSettingsMarketTypes';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../types';
import checkIsSystemAdmin from '../../../common/checkIsSystemAdmin';

type Params = {
  brandId: string;
  tab?: string;
};

const ExchangeSettingsPage: React.FC = () => {
  const { brandId, tab }: Params = useParams();

  const classes = useStyles();
  const history = useHistory();
  const intl = useIntl();

  const { roles } = useSelector((state: RootState) => state.user);

  const isSystemAdmin = checkIsSystemAdmin({ roles });

  const tabList = useMemo(() => {
    return [
      {
        label: intl.formatMessage(messages.sportline),
        value: 'sportline',
        component: <ExchangeSettingSportline />,
        isHaveAccess: isSystemAdmin,
      },
      {
        label: intl.formatMessage(messages.dateRange),
        value: 'date-range',
        component: <ExchangeSettingsDateRangeTab />,
        isHaveAccess: true,
        // everyone with access to the page will access the tab - but not everyone can edit everything
        // look for 'HIERARCHY CODE' to check actual permissions system
      },
      {
        label: intl.formatMessage(messages.marketTypes),
        value: 'market-types',
        component: <ExchangeSettingsMarketTypes />,
        isHaveAccess: true,
        // everyone with access to the page will access the tab - but not everyone can edit everything
        // look for 'HIERARCHY CODE' to check actual permissions system
      },
    ];
  }, [isSystemAdmin, intl]);

  const filteredTabList = useMemo(
    () => tabList.filter(({ isHaveAccess }) => isHaveAccess),
    [tabList],
  );

  const path = [
    { label: intl.formatMessage(messages.exchange) },
    {
      label: intl.formatMessage(messages.exchangeSettings),
      url: `/b/${brandId}/exchange-settings`,
    },
    { label: tabList.find(({ value }) => value === tab)?.label || '' },
  ];

  const firstAccessibleTab = useMemo(
    () => tabList.find((menuTab) => menuTab.isHaveAccess),
    [tabList],
  );

  return (
    <DefaultLayout path={path}>
      <Box p={4}>
        <Grid container>
          <Grid item xs={12}>
            <Paper>
              <Tabs
                value={tab || false}
                onChange={(e, value) =>
                  history.push(`/b/${brandId}/exchange-settings/${value}`)
                }
                textColor="inherit"
                variant="fullWidth"
              >
                {filteredTabList.map(({ label, value }) => (
                  <Tab
                    key={value}
                    className={classes.tabsBorder}
                    label={label}
                    value={value}
                  />
                ))}
              </Tabs>
              <Box pt={2} className={classes.parent}>
                <Switch>
                  {tabList.map(({ value, component }) => (
                    <Route
                      key={value}
                      path={`/b/:brandId/exchange-settings/${value}`}
                    >
                      {component}
                    </Route>
                  ))}
                  <Route path={`/b/:brandId/exchange-settings`}>
                    <Redirect
                      to={`/b/${brandId}/exchange-settings/${firstAccessibleTab?.value}`}
                    />
                  </Route>
                </Switch>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </DefaultLayout>
  );
};

export default ExchangeSettingsPage;
