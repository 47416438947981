import { defineMessages } from 'react-intl';

export default defineMessages({
  systemAdmin: {
    id: 'common.permission.systemAdmin',
    defaultMessage: 'System Admin',
  },
  brand: {
    id: 'common.permission.brand',
    defaultMessage: 'Brand',
  },
  owner: {
    id: 'common.permission.owner',
    defaultMessage: 'Brand owner',
  },
  superAgent: {
    id: 'common.permission.superAgent',
    defaultMessage: 'Super agent',
  },
  masterAgent: {
    id: 'common.permission.masterAgent',
    defaultMessage: 'Master agent',
  },
  agent: {
    id: 'common.permission.agent',
    defaultMessage: 'Agent',
  },
  player: {
    id: 'common.permission.player',
    defaultMessage: 'Player',
  },
  support: {
    id: 'common.permission.support',
    defaultMessage: 'Support',
  },
  marketing: {
    id: 'common.permission.marketing',
    defaultMessage: 'Marketing',
  },
  finance: {
    id: 'common.permission.finance',
    defaultMessage: 'Finance',
  },
  casinoBets: {
    id: 'common.permission.casinoBets',
    defaultMessage: 'Casino bets',
  },
  casinoGames: {
    id: 'common.permission.casinoGames',
    defaultMessage: 'Casino games',
  },
  transaction: {
    id: 'common.permission.transaction',
    defaultMessage: 'Transaction',
  },
  bet: {
    id: 'common.permission.bet',
    defaultMessage: 'Bet',
  },
  sport: {
    id: 'common.permission.sport',
    defaultMessage: 'Sport',
  },
  competition: {
    id: 'common.permission.competition',
    defaultMessage: 'Competition',
  },
  event: {
    id: 'common.permission.event',
    defaultMessage: 'Event',
  },
  market: {
    id: 'common.permission.market',
    defaultMessage: 'Market',
  },
  domains: {
    id: 'common.permission.domains',
    defaultMessage: 'Domains',
  },
  theme: {
    id: 'common.permission.theme',
    defaultMessage: 'Theme',
  },
  widget: {
    id: 'common.permission.widget',
    defaultMessage: 'Widget',
  },
  language: {
    id: 'common.permission.language',
    defaultMessage: 'Language',
  },
  ticket: {
    id: 'common.permission.ticket',
    defaultMessage: 'Ticket',
  },
  control: {
    id: 'common.permission.control',
    defaultMessage: 'Control',
  },
  permission: {
    id: 'common.permission.permission',
    defaultMessage: 'Permission',
  },
  riskBets: {
    id: 'common.permission.riskBets',
    defaultMessage: 'Risk bets',
  },
  exchange: {
    id: 'common.permission.exchange',
    defaultMessage: 'Exchange Settings',
  },
  sportline: {
    id: 'common.permission.sportline',
    defaultMessage: 'Sportline',
  },
});
